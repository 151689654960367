<template src="./ResearchGroupApplicants.html"></template>

<script>
import ChosenUniversity from "../../components/ChosenUniversity/ChosenUniversity";
import idiService from "../../services/Idi";
import { PROFILE_IMAGEN } from "@/utils/constants";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "ResearchGroupApplicants",
  components: {
    ChosenUniversity,
    ArrobaMedellinArrobitaNoData,
  },
  props: {
    TitleNotFound: {
      type: String,
      default: "Lista de aplicantes",
    },
    notFound: {
      type: String,
      default: "No hay solicitudes de aplicantes en éste momento",
    },
  },
  data() {
    return {
      institutionName: "",
      group: {},
      PROFILE_IMAGEN,
      applicants: [],
    };
  },
  created() {
    this.institutionName = this.$store.state.User.user.institutionId.name;
    this.getGroup();
  },
  methods: {
    async getGroup() {
      try {
        this.group = await idiService.listOneGroup(this.$route.params.id);
        this.applicants = await idiService.groupParticipantRequest(
          this.$route.params.id
        );
      } catch (error) {
        console.error(error);
      }
    },
    async acceptApplicant(applicant) {
      try {
        const obj = {
          groupId: Number(this.$route.params.id),
          userId: Number(applicant),
          status: "agree",
        };
        await idiService.agreeOrRejectGroupParticipantRequest(obj);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Solicitud aceptada",
          position: "is-bottom",
          type: "is-success",
        });
        this.applicants = await idiService.groupParticipantRequest(
          this.$route.params.id
        );
      } catch (error) {
        console.error(error);
      }
    },
    async declineApplicant(applicant) {
      try {
        const obj = {
          groupId: Number(this.$route.params.id),
          userId: Number(applicant),
          status: "rejected",
        };
        await idiService.agreeOrRejectGroupParticipantRequest(obj);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Solicitud rechazada",
          position: "is-bottom",
          type: "is-success",
        });
        this.applicants = await idiService.groupParticipantRequest(
          this.$route.params.id
        );
      } catch (error) {
        console.error(error);
      }
    },
    redirectProfileUser(path, follow) {
      let routeData = this.$router.resolve({
        name: path,
        params: { id: follow },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped src="./ResearchGroupApplicants.scss">
</style>