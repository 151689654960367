<template src="./ChosenUniversity.html"></template>

<script>
export default {
  name: "chosen-university",
  props: {
    institution: { type: String },
  },
};
</script>

<style lang="scss" scoped src="./ChosenUniversity.scss"></style>